import { Box, Select } from '@deckee/deck-hand';
import styled from 'styled-components';
import { Group } from '../../interfaces/group';
import Close from '../svg/close';

const SelectedFilterWrapper = styled(Box)`
  flex-wrap: wrap;
`;

const FilterOption = styled(Box)`
  background-color: #ffede9;
  color: #ff5c39;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
`;

interface GroupMultiSelectorProps {
  id?: string;
  groups: Group[];
  selectedGroupIds: Group['id'][];
  onChange: (selectedGroups: Group['id'][]) => void;
}

const GroupMultiSelector = ({
  id,
  groups,
  onChange,
  selectedGroupIds = [],
}: GroupMultiSelectorProps) => {
  return (
    <>
      <Select
        id={id}
        value=""
        onChange={(e) => {
          const selectedSet = new Set([...selectedGroupIds, e.target.value]);
          onChange(
            // Convert selectedSet to an array
            Array.from(selectedSet).filter((baseId) => baseId),
          );
        }}
      >
        <Select.Option value=""></Select.Option>
        {groups
          ?.filter((baseGroup) => selectedGroupIds.indexOf(baseGroup.id) < 0)
          .map((base) => (
            <Select.Option value={base.id} key={base.id}>
              {base.name}
            </Select.Option>
          ))}
      </Select>

      <SelectedFilterWrapper display="flex" pt="2">
        {selectedGroupIds.map((baseId) => {
          const base = groups.find((b) => b.id === baseId);
          return (
            <FilterOption
              px="2"
              py="1"
              mr="2"
              mt="2"
              fontWeight="bold"
              onClick={() => {
                onChange(selectedGroupIds.filter((b) => b !== baseId));
              }}
            >
              {base.name} <Close size={14} />
            </FilterOption>
          );
        })}
      </SelectedFilterWrapper>
    </>
  );
};

export default GroupMultiSelector;
